
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import { getUserRole, isWechat } from '@/utils/utils'
@Component({
	components: { ZButton }
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
		audio: HTMLAudioElement
	}
	private isWechat = false
	private isLoading = false
	private codesList: any = []

	private channels: any = []
	private searchForm: any = {
		package_attr_num: ''
	}
	private userRoles: any = {}

	private packageInfo = {
		channel_name: '',
		express_id: '',
		height: '',
		id: '',
		length: '',
		package_attr_num: '',
		weight: '',
		status: 2,
		status_pay: null,
		width: '',
		extra_data: null
	}
	get getPackageStatusTag() {
		const { status_pay, status } = this.packageInfo
		if ([0, 4].includes(status)) return 'canceled'
		if (status_pay === 1) {
			return 'payed'
		}
		return ''
	}
	private validateForm = {
		package_attr_num: [
			{ required: true, message: '请扫描包裹号', trigger: 'blur' }
		]
	}
	async created() {
		this.getUserRole()
		this.isWechat = isWechat()
	}

	async getUserRole() {
		this.userRoles = await getUserRole()
	}

	formatUserRole(role: number) {
		return this.userRoles[role]
	}
	async initJSSdk() {
		if (!this.isWechat) return false
		const url = location.href.split('#')[0]
		const { data } = await this.$axios.post('v1/jobline/js-sdk-config', {
			url
		})
		this.$wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			...data,
			jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
		})
	}

	async scanBarcode() {
		if (!this.$wx) return false
		await this.initJSSdk()
		this.$wx.ready(() => {
			this.$wx.scanQRCode({
				needResult: 1,
				scanType: ['barCode'],
				success: (res: any) => {
					const result = res.resultStr.split(',')
					this.searchForm.package_attr_num = result[result.length - 1]
					this.outShelfPackage()
				}
			})
		})
	}
	async outShelfPackage() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const { package_attr_num } = this.searchForm
		const { data } = await this.$axios
			.put(`/v1/jobline/package_attr_shelf/out/${package_attr_num}`)
			.catch(e => {
				return {
					data: null
				}
			})
		if (!data) return
		this.$message.success('下架成功')
		this.searchForm.package_attr_num = ''
		this.packageInfo = data
		this.codesList.unshift({
			package_attr_num: package_attr_num
		})
		this.$refs.audio.play()
	}
}
