var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("下架")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("el-row", { attrs: { gutter: 30, span: 24 } }, [
            _c(
              "div",
              {
                staticClass: "container info-card",
                class: _vm.getPackageStatusTag,
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "search-form",
                    attrs: {
                      inline: true,
                      rules: _vm.validateForm,
                      model: _vm.searchForm,
                      "label-width": "90px",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return (() => {}).apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "包裹号", prop: "package_attr_num" } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "", placeholder: "包裹单号" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.outShelfPackage.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.searchForm.package_attr_num,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "package_attr_num", $$v)
                            },
                            expression: "searchForm.package_attr_num",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "z-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.outShelfPackage },
                          },
                          [_vm._v("下架")]
                        ),
                        _vm.isWechat
                          ? _c(
                              "z-button",
                              {
                                attrs: { type: "default" },
                                on: { click: _vm.scanBarcode },
                              },
                              [_vm._v("扫码")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.packageInfo.express_id
                  ? _c(
                      "el-descriptions",
                      {
                        staticClass: "package-dimension",
                        attrs: { title: "包裹信息", column: 2 },
                      },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { span: 2, label: "包裹线路" } },
                          [_vm._v(_vm._s(_vm.packageInfo.express_title))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { span: 2, label: "下游渠道" } },
                          [
                            _c(
                              "el-tag",
                              { attrs: { type: "success", size: "small" } },
                              [_vm._v(_vm._s(_vm.packageInfo.channel_name))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { span: 2, label: "客户类型" } },
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  size: "mini",
                                  type: _vm.formatUserRole(
                                    _vm.packageInfo.user_role
                                  ).tag_type,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatUserRole(
                                      _vm.packageInfo.user_role
                                    ).name
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "尺寸" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.packageInfo.length +
                                  "x" +
                                  _vm.packageInfo.width +
                                  "x" +
                                  _vm.packageInfo.height
                              ) + "厘米"
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "重量" } },
                          [
                            _vm._v(
                              _vm._s(
                                (_vm.packageInfo.weight / 1000).toFixed(3)
                              ) + "千克"
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-table",
                  {
                    staticClass: "ordering-list",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.codesList },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "package_attr_num", label: "包裹单号" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "ctrl-btn" }, [
        _c("div", { staticClass: "total" }, [
          _vm._v("共计下架：" + _vm._s(_vm.codesList.length || "-") + "个包裹"),
        ]),
      ]),
      _c("audio", {
        ref: "audio",
        attrs: {
          src: "//res-cn.public.gblinker.com/gblinker/ops_assets/out-shelf.mp3",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }